import { Row, Col } from "antd";

import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

import TripPlannerCard from "./TripPlannerCard";

import styles from "./TripPlanner.module.scss";
import {useTranslation} from "next-i18next";

const TripPlanner = ({ pages, className = "" }) => {
  const { t } = useTranslation();

  if (isEmpty(pages)) {
    return null;
  }

  return (
    <Row className={`${styles?.row} ${className}`}>
      <Col xs={12} md={6} className={styles.pagesWrapper}>
        <TripPlannerCard
          main
          title={<h2 className={styles.title}>{t("Naplánujte si váš výlet")}</h2>}
        />
      </Col>
        {map(pages, (page) => (
            <Col xs={12} md={6} className={styles.pagesWrapper} key={`page-${page?.id}`}>
          <TripPlannerCard
            title={page?.title}
            url={
              page?.externalUrl
                ? page?.externalUrl
                : `/stranka/${page?.urlTitle}`
            }
          />
            </Col>
        ))}
    </Row>
  );
};

export default TripPlanner;
