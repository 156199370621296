import { useEffect } from "react";
import styles from "./index/index.page.module.scss";
import { indexPage } from "grandus-lib/utils/fetches";
import { get, isEmpty, isArray } from "lodash";
import EnhancedEcommerce from "grandus-lib/utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";

import ProductGrid from "components/product/grid/ProductGrid";
import MetaData from "grandus-lib/components-atomic/MetaData";
import Menu from "components/menu/Menu";
import Search from "components/header/Search";
import Carousels from "components/carousels/Carousels";
import HomepageBanners1 from "components/banners/homepageBanners1/HomepageBanners1";
import TripPlanner from "components/homepage/tripPlanner/TripPlanner";
import {useTranslation} from "next-i18next";
import {fetchTranslations} from "utils/fetches";

const Homepage = (props) => {
  const { t } = useTranslation();
  const {
    meta = {},
    banners = [],
    banners01 = [],
    productsNew = [],
    carousels = [],
    homepageStaticPageLocation1 = [],
  } = props;

  useEffect(() => {
    TagManager.push(
      EnhancedEcommerce.impressions(productsNew?.products, "homepage new products")
    );
  }, []);
  meta.options = {
    image: {
      dimensions: "1200",
      width: 1200,
      height: -1,
    },
  };
  meta.photo = get(banners, "[0].photo", {});

  return (
    <section className={styles.wrapper}>
      <MetaData {...meta} />
      <Menu />
      <Search />
      <div className={styles.content}>
        <div className={`container`}>
          {!isEmpty(carousels) ? <Carousels carousels={carousels} /> : null}
          {!isEmpty(banners01) ? (
            <HomepageBanners1
              banners={banners01}
            />
          ) : null}
          {!isEmpty(homepageStaticPageLocation1) ? (
            <TripPlanner
              className={styles?.marginBottom}
              pages={homepageStaticPageLocation1}
            />
          ) : null}
          {isArray(productsNew?.products) && !isEmpty(productsNew?.products) ? (
            <>
              <ProductGrid products={productsNew?.products} pagination={productsNew?.pagination} />
            </>
          ) : (
            <div>
              <h2>{t("Nie sú k dispozícií žiadne záznamy")}</h2>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export async function getServerSideProps(context) {
  const { locale } = context;
  const props = (await indexPage.serverSideProps(context))?.props;
  return {
    props: {
      ...(await fetchTranslations(locale)),
      ...props,
    },
  };
}

export default Homepage;
