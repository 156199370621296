import isEmpty from "lodash/isEmpty";

import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";

import styles from "./TripPlannerCard.module.scss";
import {ArrowRightOutlined} from "@ant-design/icons";

const TripPlannerCard = ({
  title,
  photo,
  className = "",
  main = false,
  url = "",
}) => {
  const content = (
    <>
      <div className={styles?.title}>{title} {!main?<ArrowRightOutlined/>:""}</div>
    </>
  );

  if (main) {
    return (
      <div
        className={`${styles?.card} ${main ? styles?.main : ""} ${className}`}
      >
        {content}
      </div>
    );
  }

  return (
    <Link href={url} as={url}>
      <a className={`${styles?.card} ${main ? styles?.main : ""} ${className}`}>
        {content}
      </a>
    </Link>
  );
};

export default TripPlannerCard;
