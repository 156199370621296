import { useState } from "react";
import { CloseCircleOutlined,  CalendarFilled, Loading3QuartersOutlined } from "@ant-design/icons";
import {filter, find, first, get, isEmpty, map, slice} from "lodash";
import Link from "next/link";
import dayjs from "dayjs";

import headStyles from "./Head.module.scss";
import styles from "./Search.module.scss";
import Form from "antd/lib/form/Form";
import { useRouter } from "next/router";

import ProductRow from "components/autocomplete/ProductRow";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "next-i18next";
import {SearchIcon} from "components/_other/icons/SearchIcon";
import {CalendarIcon} from "components/_other/icons/CalendarIcon";

const Results = ({ products, brands, categories, timeProducts, onCloseClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles?.results}>
      <div className={styles?.resultsWrapper}>
        <span className={styles?.close} onClick={onCloseClick}>
          <CloseCircleOutlined />
        </span>

        {isEmpty(products) && isEmpty(brands) && isEmpty(categories) && isEmpty(timeProducts) ? (
          <section className={styles?.noResults}>
            {t("Ľutujeme, nenašli sa žiadne výsledky")}
          </section>
        ) : (
          <>
            <section className={styles?.resultsLeft}>
              <h2 className={styles.heading}>{t("Kategórie")}</h2>
              <ul>
                {map(categories, (category) => (
                  <li key={`autosuggest-category-${category?.urlName}`}>
                    <Link
                      href="/kategoria/[category]/[[...parameters]]"
                      as={`/kategoria/${category?.urlName}`}
                    >
                      <a>{category?.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
            <section className={styles?.resultsRight}>
              <h2 className={styles.heading}>{t("Výsledky vyhľadávania")}</h2>
              {map(products, (product) => (
                <ProductRow
                  key={`autosuggest-product-${product?.id}`}
                  {...product}
                />
              ))}
            </section>
            <section className={styles?.resultsRight}>
              <h2 className={styles.heading}>{t("Vo vybranom čase")}</h2>
              {!isEmpty(timeProducts) ? (
                <>
                  {map(timeProducts, (product) => (
                    <ProductRow
                      key={`autosuggest-product-${product?.id}`}
                      {...product}
                    />
                  ))}
                </>
              ) : (
                <>
                  {t("Nenašli sa žiadne výsledky")}
                </>
              )}
            </section>
          </>
        )}
      </div>
    </div>
  );
};

const Search = ({ categoryHash = "" }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [results, setResults] = useState([]);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const fetchResults = async (value = "", dateRange = []) => {
    const [startDate, endDate] = dateRange;
    const start = (startDate) ? dayjs(startDate).format('YYYY-MM-DD'): null;
    const end = (endDate) ? dayjs(endDate).format('YYYY-MM-DD') : null;

    let parts = `search=${value}`;
    if(start){
      parts += `&start=${start}`;
    }
    if(end){
      parts += `&end=${end}`;
    }

    setLoading(true);
    const data = await fetch(`/api/pages/autosuggest?${parts}`).then(
      (result) => result.json()
    );
    setResults(data);
    setLoading(false);
  };

  const handleClose = () => {
    setValue("");
    setResults([]);
  };

  const handleChange = (e) => {
    const value = get(e, "target.value", "");
    setIsFocused(true);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setValue(value);
    setTyping(true);
    setTypingTimeout(
      setTimeout(() => {
        setTyping(false);
        fetchResults(value, dateRange);
      }, 1000)
    );
  };

  const handleSubmit = () => {
    let queryObj = {
      term: value ? value : " "
    }

    const [startDate, endDate] = dateRange;
    if(startDate){ queryObj.start = dayjs(startDate).format('YYYY-MM-DD')}
    if(endDate){ queryObj.end = dayjs(endDate).format('YYYY-MM-DD')}

    router.push({
      pathname: "/vyhladavanie/[term]/[[...parameters]]",
      query: queryObj,
    });
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const days = ['Ne', 'Po', 'Ut', 'St', 'Stv', 'Pi', 'So'];
  const months = ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'];

  return (
    <>
      <div
        className={`${headStyles.headBar} ${headStyles.search} ${
          headStyles["item-" + categoryHash] || ""
        }`}
      >
        <div className={`container ${styles.searchContainer}`}>
          <div className={styles.search}>
            <div className={styles["search-wrapper"]}>
              <Form onFinish={handleSubmit}>
                <button
                    type="submit"
                    className={styles.submitButton}
                >
                  <div
                      className={styles.searchIcon}
                      style={{
                        width: "25px",
                        height: "25px",
                        display: "inline-block",
                      }}
                  >
                    {typing || loading ? (
                        <Loading3QuartersOutlined spin />
                    ) : (
                        <SearchIcon/>
                    )}
                  </div>
                </button>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="term"
                  type="text"
                  value={value}
                  placeholder={t("Čo ťa zaujíma? Pamiatky, služby, príroda ...")}
                  className={styles.searchInput}
                />
                <div
                  className={styles.datePicker}
                  onClick={()=>{
                    setDatepickerOpen(!datepickerOpen);
                  }}
                >

                  <div className={styles.text}>
                    <div>
                      <div className={styles.icon}>
                        <CalendarIcon/>
                      </div>
                      {startDate ? dayjs(startDate).format("DD.MM.YYYY") : t("Kedy nás navštívite?")}
                    </div>
                    <div className={styles.separator}/>
                    <div>
                      <div className={styles.icon}>
                        <CalendarIcon/>
                      </div>
                      {endDate ? dayjs(endDate).format("DD.MM.YYYY") : t("Kedy odcestujete?")}
                    </div>
                  </div>
                </div>
                {datepickerOpen ? (
                  <div className={styles.datePickerWrapper}>
                    <DatePicker
                      utcOffset={0}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd.MM.yyyy"
                      onChange={(dates) => {
                        if(get(dates, "[1]", null)){
                          setDatepickerOpen(false);
                        }
                        setDateRange(dates);
                        fetchResults(value, dates);
                      }}
                      locale={{
                        localize: {
                          day: n => days[n],
                          month: n => months[n]
                        },
                        formatLong: {
                          date: () => 'mm/dd/yyyy'
                        },
                        options: {
                          weekStartsOn: 1, // 1 stands for Monday
                        },
                      }}
                      inline
                    />
                  </div>
                ): ("")}
              </Form>
            </div>
          </div>
        </div>
        {!isEmpty(results) ? (
            <Results {...results} onCloseClick={handleClose} />
        ) : null}
      </div>
    </>
  );
};

export default Search;
