import styles from "./ProductRow.module.scss";
import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import { truncate } from "lodash";

const RowProduct = ({ name, brand, urlTitle, photo, finalPriceData }) => (
  <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
    <a className={styles.product}>
        {photo?
      <Image photo={photo} size={"250x250"} type={"jpg"} />
            : <div className={styles.imagePlaceholder}/> }
      <div className={styles?.right}>
        <span className={styles.name}>
          {truncate(name, {
            length: 50,
            omission: " ...",
          })}
        </span>
      </div>
    </a>
  </Link>
);

export default RowProduct;
