import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { Row, Col } from "antd";
import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";

import styles from "./HomepageBanners1.module.scss";

const HomepageBanners1 = ({ banners = [], className = "" }) => {
  if (isEmpty(banners)) {
    return null;
  }

  return (
    <div className={`${styles["banners-location1-section"]} ${className}`}>
      <Row gutter={[10, 10]}>
        {map(banners, (banner, index) => {
          const { id, url, photo } = banner;
          return (
            <Col xs={24} md={12} key={`banners-location-02-${id}-${index}`}>
              <div className={styles.homepageBanner}>
                {url ? (
                  <Link href={url} scroll={true}>
                    <a>
                      <Image
                        photo={photo}
                        size={"730x365__cropped"}
                        type={"jpg"}
                        useNextImage={true}
                      />
                    </a>
                  </Link>
                ) : (
                  <Image
                    photo={photo}
                    size={"730x365__cropped"}
                    type={"jpg"}
                    useNextImage={true}
                  />
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default HomepageBanners1;
