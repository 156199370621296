import React from "react";

export const SearchIcon = (props) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <ellipse
                cx="11.3145"
                cy="11.3137"
                rx="8"
                ry="8"
                transform="rotate(45 11.3145 11.3137)"
                stroke="currentColor"
                strokeWidth="2"
            />
            <line
                x1="16.9708"
                y1="15.5566"
                x2="24.0418"
                y2="22.6276"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    )
}
